.Button {
  color: white;
  width: 25%;
  height: 14%;
  font-size: 6vmin;
  border-color: black;
}

.Button:focus {
  outline: 0px;
}

.Button:active {
  opacity: 0.5;
}

.BlackButton {
  background-color: #434343;
}

.GrayButton {
  background-color: #5d5e5e;
}

.OrangeButton {
  background-color: #de222a;
}

.LargeButton {
  width: 50%;
  text-align: left;
  padding-left: 12%;
}
